import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  PreloadedQuery,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay';
import { graphql } from 'relay-runtime';
import type {
  ThemeContextProviderQuery as ThemeContextProviderQueryType,
  ThemeContextProviderQuery$data as ThemeContextProviderQueryResponse,
} from './__generated__/ThemeContextProviderQuery.graphql';
import { useLoaderData } from 'react-router-dom';
import { LoaderData } from './Types';
import { ThemeContextProviderUpdateEntityThemeMutation$data } from './__generated__/ThemeContextProviderUpdateEntityThemeMutation.graphql';
import { ThemeProvider } from '@mui/material';
import { darkTheme } from '../portals/DarkTheme';
import { lightTheme } from '../portals/LightTheme';
import { getSystemTheme } from './Tools';
import classNames from 'classnames';
import layoutStyles from '../../../stylesheets/components/layout.module.scss';

export const ThemeContextProviderQuery = graphql`
  query ThemeContextProviderQuery {
    entities(returnSelf: true) {
      edges {
        node {
          __id
          __typename
          theme
        }
      }
    }
  }
`;

export const ThemeContextProviderUpdateEntityThemeMutation = graphql`
  mutation ThemeContextProviderUpdateEntityThemeMutation(
    $input: EntityUpdateThemeInput!
  ) {
    entityUpdateTheme(input: $input) {
      errors
      entity {
        __typename
        theme
      }
    }
  }
`;

interface ThemeContext {
  theme: string | null;
  toggleTheme?: () => void;
}

export const ThemeContext = createContext<ThemeContext>({
  theme: null,
  toggleTheme: () => undefined,
});

interface ThemeContextProviderProps {
  children: React.ReactNode;
  isLoggedIn: boolean;
  preloadedQuery?: PreloadedQuery<ThemeContextProviderQueryType>;
}

export const ThemeContextProvider = ({
  children,
  isLoggedIn,
  preloadedQuery,
}: ThemeContextProviderProps) => {
  if (isLoggedIn)
    if (preloadedQuery) {
      return (
        <RelayThemeProviderLoadQueryWrapper preloadedQuery={preloadedQuery}>
          {children}
        </RelayThemeProviderLoadQueryWrapper>
      );
    } else {
      return (
        <RelayThemeProviderRouterWrapper>
          {children}
        </RelayThemeProviderRouterWrapper>
      );
    }
  else {
    const theme = getSystemTheme();

    return (
      <div
        className={classNames(layoutStyles.fullHeight, {
          dark: theme === 'dark',
        })}
      >
        <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
          <ThemeContext.Provider
            value={{
              theme,
            }}
          >
            {children}
          </ThemeContext.Provider>
        </ThemeProvider>
      </div>
    );
  }
};

const RelayThemeProviderRouterWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const loaderQueryReference = (useLoaderData() as LoaderData)
    .themeContextProviderLoadQuery;
  const [queryReference] = useQueryLoader<ThemeContextProviderQueryType>(
    ThemeContextProviderQuery,
    loaderQueryReference
  );

  const data = usePreloadedQuery(
    ThemeContextProviderQuery,
    queryReference as PreloadedQuery<
      ThemeContextProviderQueryType,
      ThemeContextProviderQueryResponse
    >
  );

  return <RelayThemeProvider data={data}>{children}</RelayThemeProvider>;
};

const RelayThemeProviderLoadQueryWrapper = ({
  children,
  preloadedQuery,
}: {
  children: React.ReactNode;
  preloadedQuery: PreloadedQuery<ThemeContextProviderQueryType>;
}) => {
  const [queryReference] = useQueryLoader<ThemeContextProviderQueryType>(
    ThemeContextProviderQuery,
    preloadedQuery
  );

  const data = usePreloadedQuery(
    ThemeContextProviderQuery,
    queryReference as PreloadedQuery<
      ThemeContextProviderQueryType,
      ThemeContextProviderQueryResponse
    >
  );

  return <RelayThemeProvider data={data}>{children}</RelayThemeProvider>;
};

const RelayThemeProvider = ({
  children,
  data,
}: {
  children: React.ReactNode;
  data: ThemeContextProviderQueryResponse;
}) => {
  const [theme, setTheme] = useState(
    data?.entities?.edges?.[0]?.node?.theme || null
  );

  const entityId = data?.entities?.edges?.[0]?.node?.__id || null;
  const entityTypename = data?.entities?.edges?.[0]?.node?.__typename || null;

  const [commitMutation] = useMutation(
    ThemeContextProviderUpdateEntityThemeMutation
  );

  const updateTheme = useCallback(
    (theme: string) => {
      commitMutation({
        variables: {
          input: {
            entityThemeData: {
              id: entityId,
              theme: theme,
            },
          },
        },
        onCompleted: (response) => {
          const responseData =
            response as ThemeContextProviderUpdateEntityThemeMutation$data;
          if (!responseData || !responseData.entityUpdateTheme) {
            alert('There was an error updating the theme');
            return;
          }
          if (
            responseData.entityUpdateTheme.errors &&
            responseData.entityUpdateTheme.errors.length > 0
          ) {
            alert(responseData.entityUpdateTheme.errors.join('<br>'));
            return;
          }
        },
        onError: (error) => {
          alert('There was an error updating the theme');
          console.error(error);
        },
        optimisticResponse: {
          entityUpdateTheme: {
            entity: {
              id: entityId,
              __typename: entityTypename,
              theme: theme === 'standard' ? 'dark' : 'standard',
            },
            errors: null,
          },
        },
      });
    },
    [commitMutation, entityId, entityTypename]
  );

  useEffect(() => {
    if (theme && theme !== data?.entities?.edges?.[0]?.node?.theme) {
      updateTheme(theme);
    }
  }, [theme, data?.entities?.edges, updateTheme]);

  const toggleTheme = () => {
    setTheme(theme === 'standard' ? 'dark' : 'standard');
  };

  if (!theme) setTheme(getSystemTheme());

  return (
    // <div className={theme === 'dark' ? 'dark' : undefined}>
    <div
      className={classNames(layoutStyles.fullHeight, {
        dark: theme === 'dark',
      })}
    >
      <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
        <ThemeContext.Provider
          value={{
            theme,
            toggleTheme,
          }}
        >
          {children}
        </ThemeContext.Provider>
      </ThemeProvider>
    </div>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
